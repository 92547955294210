import React from 'react'
import tw, { styled } from 'twin.macro'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'

const Section = tw.section`h-[220px] md:h-auto md:pt-36`
const Wrap = tw.div`h-full max-w-screen-2xl mx-auto px-5 w-full flex items-end md:items-center`
const Content = styled(motion.div)`
	${tw`flex flex-col items-end justify-center w-full gap-6 my-8 md:mb-0 md:pb-0`}
`
const Headline = styled(motion.div)`
	${tw`absolute right-0 text-6xl uppercase text-primaryDark leading-075 sm:text-8xl sm:leading-075 md:text-9xl md:leading-075 font-futuraExtra xs:tracking-3 2xl:text-180px`}/* transform-origin: right center;
	transform: rotateY(-45deg); */
`
const HeadlineWrap = styled(motion.div)`
	${tw`relative w-full h-[46px] sm:h-[72px] md:h-[96px] 2xl:h-[140px]`}
	perspective: 1200px;
	@media (max-width: 768px) {
		perspective: 600px;
	}
`

const Text = tw.p`inline-flex font-futuraBook absolute top-0 -left-1 lg:-left-5 -translate-x-full text-base sm:text-copyXl sm:leading-none tracking-1 lg:text-3xl leading-none text-right`

const WpAcfSimpleheaderBlock = ({ data }) => {
	const { anchor } = data?.attributes
	const module = data?.modulesSimpleheader

	return (
		<Section {...(anchor !== '' ? { id: anchor } : {})} className='emf-mb-spacing'>
			<Wrap>
				<Content>
					{module.headline && (
						<HeadlineWrap>
							<Headline
								animate={{
									opacity: [0, 0.5, 1],
									rotateY: [0.01, 0.01, -45],
									y: [-100, 0, 0],
									originX: [1, 1, 1],
								}}
								transition={{
									duration: 1,
									ease: 'easeInOut',
									times: [0, 0.6, 1],
								}}
							>
								{module.headline}
								{module.text && <Text>{parse(module.text)}</Text>}
							</Headline>
						</HeadlineWrap>
					)}
				</Content>
			</Wrap>
		</Section>
	)
}
export default WpAcfSimpleheaderBlock
